import React from 'react'
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../img/logo.png';
import { motion } from 'framer-motion';
import menu from '../img/Menu.pdf'

const Nav = () => {
    const { pathname } = useLocation();
    return (
        <StyledNav>
            <Link id="logo" to="/"><img src={Logo} alt="" /></Link>
            <ul>
                <li>
                    <Link to="/">Home</Link>
                    <Line transition={{ duration: 0.75 }} initial={{ width: '0%' }} animate={{ width: pathname === '/' ? '50%' : '0%' }} />
                </li>
                <li>
                    <a href={menu} target="_blank">Menu</a>
                    <Line transition={{ duration: 0.75 }} initial={{ width: '0%' }} animate={{ width: pathname === '/menu' ? '50%' : '0%' }} />
                </li>
                <li>
                    <a target="_blank" href="https://www.order.store/ca/store/spa-cafe/r-ZvCdFHXKaFb0N-uFytkg">Order Now</a>
                </li>
                <li>
                    <Link to="/contact">Contact</Link>
                    <Line transition={{ duration: 0.75 }} initial={{ width: '0%' }} animate={{ width: pathname === '/contact' ? '50%' : '0%' }} />
                </li>
                <li>
                    <Link to="/about">About</Link>
                    <Line transition={{ duration: 0.75 }} initial={{ width: '0%' }} animate={{ width: pathname === '/faq' ? '50%' : '0%' }} />
                </li>
            </ul>
        </StyledNav>
    )
}

const Line = styled(motion.div)`
    height: 0.3rem;
    background: #5ba091;
    width: 0%;
    position: absolute;
    bottom: -80%;
    left: 60%;
    @media (max-width: 1300px){
        left: 0%;
        top: 99%;
    }
`

const StyledNav = styled.nav`
    min-height: 10vh;
    display: flex;
    margin: auto;
    justify-content: space-between;
    align-items:center;
    padding: 1rem 10rem;
    background-color: #f8f5f5;
    position: sticky;
    top: 0;
    z-index: 10;
    a{
        color: black;
        text-decoration: none;
        cursor: pointer;
        font-size: 17px;
        transition: 0.2s ease-in-out;
        &:hover{
            color: #5ba091;
        }
    }

    ul{
        display: flex;
        list-style: none;
    }

    #logo{
        img{
            width: 100px;
        }
    }

    li{
        padding-left: 5rem;
        position: relative;
        
    }

    @media (max-width: 1300px){
        flex-direction: column;
        padding: 2rem 1rem;
        #logo{
            display: inline-block;
        }
        ul{
            padding: 1rem;
            justify-content: space-around;
            width: 100%;
        }

        li{
            padding: 0;
        }
    }
`

export default Nav
