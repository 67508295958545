import React, { useRef } from 'react'
import { Rerousel } from 'rerousel';
import styled from 'styled-components';
//images
import Bagel from '../img/carousel/bagel.jpg';
import Cup from '../img/carousel/cup.JPG';
import Egg from '../img/carousel/egg.jpg';
import Fruit from '../img/carousel/fruitoat.jpg';
import Strawberry from '../img/carousel/strawmango.jpg';
import Waffle from '../img/carousel/waffle.jpeg';


const photos = [Bagel, Cup, Egg, Fruit, Strawberry, Waffle];

const Carousel = () => {
    const customerLogo = useRef(null);
    return (
        <Container>
            <Rerousel interval={2500} itemRef={customerLogo}>
                {photos.map((c, i) => {
                    return <StyleImage key={i} src={c} ref={customerLogo} />;
                })}
            </Rerousel>
        </Container>
    )
}

const StyleImage = styled.img`
    width: 25vw;
    height: 25vh;
    padding: 2rem;
    object-fit: cover;
    border-radius: 50px;
    @media (max-width: 1300px){
        width: 70vw;
    }
`

const Container = styled.div`
    /* padding-top: 100px;*/
    padding-bottom: 100px; 
`

export default Carousel
