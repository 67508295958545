import React from 'react'
import styled from 'styled-components';
//photos
import Main from '../img/fruitoat.jpg';
import Wave from '../components/Wave';

import { pageAnimation, titleAnim, fade, photoAnim } from '../Animation';
import { motion } from 'framer-motion';

import TopHome from '../components/TopHome';
import Carousel from '../components/Carousel';
import Cards from '../components/Cards';
import ScrollTop from '../components/ScrollTop';

const Home = () => {
    return (
        <motion.div exit='exit' variants={pageAnimation} initial='hidden' animate='show'>
            <TopHome />
            <Cards />
            <Carousel />
            <ScrollTop />
        </motion.div>
    )
}

const StyleHome = styled(motion.div)`
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5rem 20rem;
    color: black;
`

const Container = styled(motion.div)`
    flex: 1;
    padding-right: 5rem;
    z-index: 2;
    h1{
        font-weight: lighter;
        color: black;
        font-size: 40px;
    }
    h2{
        font-weight: lighter;
        color: black;
        font-size: 30px;
    }
    span{
        color: #5ba091;
    }
    @media (max-width: 1300px){
        
    }
`

const StyleButton = styled(motion.button)`
    font-weight: bold;
        font-size: 1.1.rem;
        cursor: pointer;
        padding: 1rem 2rem;
        border: 3px solid #5ba091;
        background: transparent;
        color: black;
        transition: all 0.5s ease;
        margin-bottom: 45px;
        &:hover{
            background-color: #5ba091;
            color: white;
        }
`

const Photo = styled.div`
    flex: 1;
    overflow: hidden;
    z-index: 10;
    img{
        width:100%;
        height: 70vh;
        object-fit:cover;
    }
`

export default Home
