const menu = [
    {
        id: 1,
        title: 'Poached Eggs',
        category: 'breakfast',
        price: 12.95,
        img: './images/item-1.jpeg',
        desc: `2 poached eggs on crustini with greens and oven potatoes.`,
    },
    {
        id: 2,
        title: 'Omelette',
        category: 'breakfast',
        price: 14.95,
        img: './images/item-2.jpeg',
        desc: `3-egg omelette filled with peppers, onion, and spinach with toast and greens.`,
    },
    {
        id: 3,
        title: 'avocado toast (vegan)',
        category: 'breakfast',
        price: 12.95,
        img: './images/item-3.jpeg',
        desc: `Avocado, tomato, and cucumber on multigrain with greens. (add poached eggs $3)`,
    },
    {
        id: 4,
        title: 'waffles & fruits (vegan)',
        category: 'breakfast',
        price: 8.95,
        img: './images/item-4.jpeg',
        desc: `Whole wheat waffle with fruit compote and maple syrup. (add second waffle $2)`,
    },
    {
        id: 5,
        title: 'The big c',
        category: 'breakfast',
        price: 9.95,
        img: './images/item-5.jpeg',
        desc: `Omelette croissant sandwhich`,
    },
    {
        id: 6,
        title: 'oatmeal cup (vegan option)',
        category: 'breakfast',
        price: 6.95,
        img: './images/item-6.jpeg',
        desc: `Steel cut oats with almond milk and fruit compote. (add side of mixed berry classic super smoothie $4)`,
    },
    {
        id: 7,
        title: 'yogurt & granola',
        category: 'breakfast',
        price: 8.99,
        img: './images/item-7.jpeg',
        desc: `SPA granola with greek yogurt and fruit.`,
    },
    {
        id: 8,
        title: 'Chicken caesar wrap',
        category: 'lunch',
        price: 14.95,
        img: './images/item-8.jpeg',
        desc: `Roasted Chicken, kale, and cashew garlic sauce.`,
    },
    {
        id: 9,
        title: 'roast beef sandwich',
        category: 'lunch',
        price: 16.95,
        img: './images/item-9.jpeg',
        desc: `Roast beef & caramelized onion, with coleslaw.`,
    },
    {
        id: 11,
        title: 'open-faced tuna sandwich',
        category: 'lunch',
        price: 12.95,
        img: './images/item-7.jpeg',
        desc: `Seasoned Rio tuna, arugula, tomato, onion, and peppers.`,
    },
    {
        id: 12,
        title: 'eggplant parmigiana sandwich (vegan option)',
        category: 'lunch',
        price: 12.95,
        img: './images/item-7.jpeg',
        desc: `Roasted eggplant, bell peppers, and cheese.`,
    },
    {
        id: 13,
        title: 'falafel wrap (vegan option)',
        category: 'lunch',
        price: 12.95,
        img: './images/item-7.jpeg',
        desc: `Baked falafel, tahini, pickles, turnip, onion, and romaine.`,
    },
    {
        id: 14,
        title: 'spa salad (vegan)',
        category: 'bowls',
        price: 10.95,
        img: './images/item-7.jpeg',
        desc: `Fresh mixed greens, cucomber, tomato, red onion, peppers, and olives with SPA dressing.`,
    },
    {
        id: 15,
        title: 'kale caesar salad (vegan)',
        category: 'bowls',
        price: 10.95,
        img: './images/item-7.jpeg',
        desc: `Kale, cashew garlic dressing, "bacon" chickpeas, crustini, and garlic confit.`,
    },
    {
        id: 16,
        title: 'quinao (vegan)',
        category: 'bowls',
        price: 10.95,
        img: './images/item-7.jpeg',
        desc: `Quinoa with broccoli, cucomber, tomato, peppers, and mixed greens.`,
    },
    {
        id: 17,
        title: 'pasta (vegan)',
        category: 'bowls',
        price: 10.95,
        img: './images/item-1.jpeg',
        desc: `Pasta with roasted cauliflower, pepper, toasted red pepper, tomato with olive oil dressing.`,
    },
    {
        id: 18,
        title: 'vegetarian chilli (vegan)',
        category: 'bowls',
        price: 8.95,
        img: './images/item-2.jpeg',
        desc: ``,
    },
    {
        id: 19,
        title: 'soup of the day',
        category: 'bowls',
        price: '5.95(S) $8.95(L)',
        img: './images/item-3.jpeg',
        desc: `Visit us or call to know the soup of the day!`,
    },
    {
        id: 20,
        title: 'roast chicken',
        category: 'lunch',
        price: 17.95,
        img: './images/item-4.jpeg',
        desc: `1/2 chicken with roasted potatoes, rice, and side SPA salad.`,
    },
    {
        id: 21,
        title: 'roast beef',
        category: 'lunch',
        price: 17.95,
        img: './images/item-5.jpeg',
        desc: `6-ounce beef with roasted potatoes and side SPA salad.`,
    },
    {
        id: 22,
        title: 'slow-baked fish',
        category: 'lunch',
        price: 17.95,
        img: './images/item-6.jpeg',
        desc: `With rice, roasted vegetables, and side SPA salad.`,
    },
    {
        id: 23,
        title: 'falafel wrap (vegan)',
        category: 'lunch',
        price: 17.95,
        img: './images/item-7.jpeg',
        desc: `With roasted potatoes and side SPA salad.`,
    },
    {
        id: 24,
        title: 'avocado mango (vegan)',
        category: 'drinks',
        price: "5.75(8oz) $8.75(16oz)",
        img: './images/item-8.jpeg',
        desc: `Avocado, mango, orange juice, protein, choice of superfood.`,
    },
    {
        id: 25,
        title: 'chocolate peanut butter (vegan)',
        category: 'drinks',
        price: "5.75(8oz) $8.75(16oz)",
        img: './images/item-9.jpeg',
        desc: `Banana, almond milk, cacao nibs, protein, maple syrup, peanut butter, choice of superfood.`,
    },
    {
        id: 26,
        title: 'banana date ginger',
        category: 'drinks',
        price: "5.75(8oz) $8.75(16oz)",
        img: './images/item-7.jpeg',
        desc: `Banana, 2% milk, dates, greek yogurt, ginger, cardamom, protein, choice of superfood.`,
    },
    {
        id: 27,
        title: 'matcha (vegan)',
        category: 'drinks',
        price: "5.75(8oz) $8.75(16oz)",
        img: './images/item-7.jpeg',
        desc: `Banana, matcha, almond milk, protein, choice of superfood`,
    },
    {
        id: 28,
        title: 'tropic thunder',
        category: 'drinks',
        price: "5.75(8oz) $8.75(16oz)",
        img: './images/item-7.jpeg',
        desc: `Pineapple, coconut milk, pineapple juice, greek yogurt, coconut, honey, protein, choice of superfood.`,
    },
    {
        id: 29,
        title: 'mixed berry classic',
        category: 'drinks',
        price: "5.75(8oz) $8.75(16oz)",
        img: './images/item-7.jpeg',
        desc: `Blueberries, strawberries, orange juice, greek yogurt, protein, choice of superfood.`,
    },


];
export default menu;