import React, { useState } from 'react';
import styled from 'styled-components';
import Toggle from './Toggle';
import { AnimateSharedLayout } from 'framer-motion';
import { fade } from '../Animation';
import { motion } from 'framer-motion';

const FaqSection = () => {

    const [faqToggle, setFaqToggle] = useState(false);

    return (
        <Faq variants={fade} animate={fade} >
            <h2>Any Questions <span>FAQ</span></h2>
            <AnimateSharedLayout>
                <Toggle title="I have dietary restrictions. What accommodations are available to me?">
                    <div className="anwser">
                        <p>We will provide as many alternatives as possible for your specific concerns. For example, nut products are packaged by serving size and opened by individual clients for safety. Please notify us when you are ordering.</p>
                    </div>
                </Toggle>
                <Toggle title="Why is it called Spa Cafe? What sets us apart?">
                    <div className="anwser">
                        <p>Our Spa Cafe approaches the classic spa objectives of beauty and wellness with the main feature of a cafe: great food! Based on the goal of understanding your well-being and how it can benefit from what you enjoy eating, our Spa Cafe.</p>
                    </div>
                </Toggle>
                <Toggle title="Can I book events at the Spa Cafe?">
                    <div className="anwser">
                        <p>Yes! Please contact us to book as soon as possible. We recommend at least two weeks prior to your desired date in order to customize meals.</p>
                    </div>
                </Toggle>
                <Toggle title="Does the Spa Cafe offer catering?">
                    <div className="anwser">
                        <p>Yes! Please contact us at least two weeks prior to your event to organize your menu for a minimum of 25 guests.</p>
                    </div>
                </Toggle>
                <Toggle title="What is i'm not a member yet? How will that effect my treatment?">
                    <div className="anwser">
                        <p>Membership does not affect your food at the Spa Cafe. The benefit of membership at the Spa Cafe is recommentdations based on your nutrition profile and dietary concerns which are accessed with your membership.</p>
                    </div>
                </Toggle>
                <Toggle title="Can I start my Omniscient Wellness Journey at the Spa Cafe?">
                    <div className="anwser">
                        <p>Absolutely! All of our staff members are trained to help you start your journey at any time.</p>
                    </div>
                </Toggle>
            </AnimateSharedLayout>
        </Faq>
    )
}

const About = styled(motion.div)`
    min-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5rem 10rem;
    color: black;
    P{
        font-size: 20px;
    }
    @media (max-width: 1300px){
            display: block;
            padding: 2rem 2rem;
            text-align: center;
    }
`;

const Faq = styled(About)`
    display: block;
    span{
        display: block;
        color: #5ba091;
    }

    h2{
        padding-bottom: 2rem;
        font-weight: lighter;
        font-size: 50px;
    }

    .faq-line{
        background: rgb(91,160,145);
        background: linear-gradient(90deg, rgba(91,160,145,1) 34%, rgba(255,255,255,1) 90%);
        height: 0.2rem;
        margin: 2rem 0rem;
        width: 100%;
    }

    .question{
        padding: 1rem 0rem;
        cursor: pointer;
    }

    .answer{
        padding: 2rem 0rem;
        p{
            padding: 1rem 0rem;
        }
    }
`


export default FaqSection;