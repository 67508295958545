import React from 'react';
//anim
import { motion } from 'framer-motion';
import { pageAnimation, titleAnim, reveal, fade } from '../Animation';
import styled from 'styled-components';
import ScrollTop from '../components/ScrollTop';
import Form from '../components/Form';
import ContactRight from '../components/ContactRight';

const ContactUs = () => {
    return (
        <ContactStyle
            exit="exit"
            variants={pageAnimation}
            initial="hidden"
            animate="show"
            style={{ background: "#fff" }}
        >
            <ScrollTop />
            <div className="title">
                <Title variants={reveal}>Contact</Title>
                <Line />
            </div>
            <SubTitle>
                <Hide>
                    <Phone>(343) - 984 - 4280</Phone>
                </Hide>
            </SubTitle>
            <div>
                <Form variants={fade} />
            </div>
        </ContactStyle>
    )
}

const ContactStyle = styled(motion.div)`
    padding: 5rem 10rem;
    color: #353535;
    min-height: 90vh;
    overflow-x: hidden;
`

const SubTitle = styled.div`
    margin-bottom: 2rem;
    color: black;
    overflow-x: hidden;
    h2{
        position: relative;
        left: 42%;
        font-size: 3rem;
        @media (max-width: 1300px){
            visibility: hidden;
        }
    }
`

const Hide = styled.div`
    overflow: hidden;
`

const Title = styled(motion.h2)`
    text-align: center;
    font-size: 40px;
    margin-bottom: 2rem;
    margin-top: -2%;
`

const Phone = styled.p`
    position: relative;
    font-size: 20px;
    left: 45%;
    @media (max-width: 1300px){
        position: absolute;
            left: 30%;
            width: 100vw;
    }
`

const Line = styled.div`
  width: 5rem;
  height: 0.25rem;
  background: #5ba091;
  margin-left: auto;
  margin-right: auto;
`

export default ContactUs;


