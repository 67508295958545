import React from 'react'
import styled from 'styled-components';

const ContactRight = () => {
    return (
        <Container>
            <Line />
            <Hours>
                <h2>Hours</h2>
                <p>Monday - Sunday</p>
                <p>8am - 8pm</p>
            </Hours>
            <Map src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2800.0358706764437!2d-75.69749028416548!3d45.42877824382689!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cce04fdb1b17dc9%3A0x6d3c0ecfc8453ca!2s18%20Murray%20St%2C%20Ottawa%2C%20ON%20K1N%201G9!5e0!3m2!1sen!2sca!4v1621448836026!5m2!1sen!2sca" width="400" height="300" allowfullscreen="" loading="lazy"></Map>
        </Container>
    )
}

const Container = styled.div`
    position: relative;
    left: 60%;
`

const Line = styled.hr`
    position: inherit;
    background: #5ba091;
    height: 5px;
    width: 20%;
    right: 60%;
    top: 15vh;
    border: none;
    border-radius: 5px;
    transform: rotate(90deg);
`

const Hours = styled.div`
    position: inherit;
    width: 10vw;
    text-align: center;
    align-items: center;
    top: -18vh;
    left: 10%;
    @media (max-width: 1300px){
        width: 40vw;
        top: 100%;
    }
    //ipad pro
    @media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {
    left: 40%;
    top: -13vh;
    }
`

const Map = styled.iframe`
    position: relative;
    width: 500px;
    height: 300px;
    border: none;
    margin-bottom: -280px;
    border-radius: 20px;
    top: -10vh;
    @media (max-width: 1300px){
        width: 230px;
        height: 230px;
        left: 20%;
        top: 100%;
        margin-bottom: 0px;
        margin-top: -10%;
        align-items: center;
    }
    //ipad pro
    @media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {
    width: 400px;
    height: 200px;
    left: 30%;
    }

`

export default ContactRight;
