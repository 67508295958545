import React from 'react';
import Nav from './Navbar/Nav';
import Menu from './pages/Menu';
import ContactUs from './pages/ContactUs';
import About from './pages/About';

import Home from './pages/Home';
import { Route, Switch, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Footer from './components/Footer';
import GlobalStyle from './components/GlobalStyle';

function App() {
  return (
    <div className="app">
      <GlobalStyle />
      <Nav />
      <AnimatePresence exitBeforeEnter>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/menu" exact>
            <Menu />
          </Route>
          <Route path="/contact">
            <ContactUs />
          </Route>
          <Route path="/about">
            <About />
          </Route>
        </Switch>
      </AnimatePresence>
      <Footer />
    </div>
  );
}

export default App;
