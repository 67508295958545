import React, { useState } from 'react'
import Items from '../components/Data';

import styled from 'styled-components';
import ScrollTop from '../components/ScrollTop';

import MenuCategory from '../components/MenuCat';
import MenuContent from '../components/MenuContent'

import { reveal } from '../Animation'
import { motion } from 'framer-motion';

import Wave from '../components/Wave';

const Menu = () => {
    const [menuItems, setMenuItems] = useState(Items);
    const [categories, setCategories] = useState([]);

    const filterItems = (category) => {
        if (category === 'all') {
            setMenuItems(Items);
            return;
        }
        const newItems = Items.filter((Items) => Items.category === category)
        setMenuItems(newItems);
    }

    return (
        <main>
            <MainMenu initial="hidden" animate="show">
                <Wave />
                <div className="title">
                    <Title variants={reveal}>Menu</Title>
                    <Line />
                </div>
                {/* <Menus className="menu">
                    <img src={MenuOne} />
                    <img src={MenuTwo} />
                </Menus> */}
                <MenuCategory filterItems={filterItems} />
                <MenuContent items={menuItems} />
            </MainMenu>
            <ScrollTop />
        </main>
    )
}

// const Menus = styled(motion.div)`
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     align-items: center;
//     gap: 100px;
//     img{
//         border-radius: 8px;
//         width: 40rem;
//     }
// `

const Title = styled(motion.h2)`
    text-align: center;
    font-size: 40px;
    margin-bottom: 2rem;
`

const Line = styled.div`
  width: 5rem;
  height: 0.25rem;
  background: #5ba091;
  margin-left: auto;
  margin-right: auto;
`

const MainMenu = styled(motion.div)`
    padding: 5rem 0;
    overflow: hidden;
    min-height: 100vh;
    @media (max-width: 1300px){
        overflow-x: hidden;
    }
`

export default Menu
