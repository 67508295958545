import React from 'react'
import styled from 'styled-components';

const Cards = () => {
    return (
        <div>
            <CardContainer>
                <Card>
                    <h1>TURN SELF-CARE INTO A WAY OF LIFE</h1>
                    <p>
                        Omniscient Wellness Centre delivers a holistic wellness spa experience and benefits using homeopathic treatments, balanced care and education. Under one roof members will have access to all the resources to enable them to live their best and most healthy life.</p>
                </Card>
            </CardContainer>
        </div>
    )
}

const Card = styled.div`
    position: relative;
    width: 40vw;
    line-height: 40px;
    left: 30vw;
    h1{
        position: relative;
        text-align: center;
        top: 25%;
    }
    p{
        text-align: center;
    }
    @media (max-width: 1300px){
        width: 70vw;
        left: 15vw;
    }
`

const CardContainer = styled.div`
    padding-top: 55px;
    padding-bottom: 100px;
`



export default Cards
