import React from 'react'
import styled from 'styled-components';
//photos
import Main from '../img/fruitoat.jpg';
import Wave from '../components/Wave';


import { titleAnim, fade, photoAnim } from '../Animation';
import { motion } from 'framer-motion';

const TopHome = () => {
    return (
        <StyleHome>
            <Container>
                <motion.div className="title">
                    <Hide>
                        <motion.h1 variants={titleAnim}>Nourish your body</motion.h1>
                    </Hide>
                    <Hide>
                        <motion.h2 variants={titleAnim}>Get a <span>taste</span> of spa life.</motion.h2>
                    </Hide>
                </motion.div>
                <a variants={fade} target="_blank" href="https://www.order.store/ca/store/spa-cafe/r-ZvCdFHXKaFb0N-uFytkg"><button>Order Now</button></a>
                <a id="giftcard" variants={fade} target="_blank" href="https://squareup.com/gift/ML8F57D4CAK7K/order"><button>Gift Cards</button></a>
            </Container>
            <Photo>
                <motion.img variants={photoAnim} src={Main} alt="fruit bowl" />
            </Photo>
            <Wave />
        </StyleHome>
    )
}

const StyleHome = styled(motion.div)`
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5rem 20rem;
    color: black;
    @media (max-width: 1300px){
            display: block;
            padding: 2rem 2rem;
            text-align: center;
    }
`

const Container = styled(motion.div)`
    flex: 1;
    padding-right: 5rem;
    z-index: 2;
    h1{
        font-weight: lighter;
        color: black;
        font-size: 40px;
    }
    h2{
        font-weight: lighter;
        color: black;
        font-size: 30px;
    }
    span{
        color: #5ba091;
        font-weight: 400;
    }
    #giftcard{
        margin-left: 20px;
    }
    @media (max-width: 1300px){
            padding: 0;
            button{
                margin: 2rem 0rem 5rem 0rem;
            }
    }
`

const Photo = styled.div`
    flex: 1;
    overflow: hidden;
    z-index: 2;
    img{
        width:100%;
        height: 70vh;
        object-fit:cover;
    }
`

const Hide = styled.div`
    overflow: hidden;
`

export default TopHome
