import React from 'react'
import styled from 'styled-components';
import Wave from '../components/Wave';
import { motion } from 'framer-motion';
import { fade } from '../Animation';

const MenuContent = ({ items }) => {
    return (
        <div>
            <SectionCenter>

                {items.map((menuItem) => {
                    const { id, title, category, desc, price } = menuItem;
                    return <MenuItem variants={fade} key={id}>
                        <itemInfo >
                            <header>
                                <h4>{title}</h4>
                                <h4 className="price">${price}</h4>
                            </header>
                            <p>{desc}</p>
                        </itemInfo>
                    </MenuItem>
                })}

            </SectionCenter>
        </div>
    )
}


const MenuItem = styled(motion.div)`
  display: grid;
  gap: 1rem 2rem;
  max-width: 25rem;
  border: 2px #a5a5a5 solid;
  border-radius: 5px;
  margin-bottom: 10%;
  background: rgb(200,241,232);
background: linear-gradient(165deg, rgba(200,241,232,1) 1%, rgba(255,255,255,1) 20%);
  transition: 0.3s ease-in-out;
  z-index: 3;
  &:hover{
      transform: scale(1.05);
  }
`

const SectionCenter = styled(motion.div)`
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-evenly;
  padding-top: 10%;
  @media (max-width: 1300px){
    grid-template-columns: auto;
    padding-left: 5%;
    width: 90vw;
  }

  header{
      text-align: center;
      text-transform: uppercase;
  }

  p{
      text-align: center;
  }
`

const itemInfo = styled.div`
    header{
        display: flex;
        color: blue;
    }
        
`

/**ZINDEX FOR WAVE GOING OVER MENU ITEMS!! */




export default MenuContent
