import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

    html{
        @media (max-width: 1700px){
            font-size:75%;
        }
    }

    body{
        font-family: 'Inter', sans-serif;
    }

    button{
        font-weight: bold;
        font-size: 1.1.rem;
        cursor: pointer;
        padding: 1rem 2rem;
        border: 3px solid #5ba091;
        background: transparent;
        color: black;
        transition: all 0.5s ease;
        font-family: 'Inter', sans-serif;
        margin-bottom: 45px;
        border-radius: 5px;
        &:hover{
            background-color: #5ba091;
            color: white;
        }
    }

    h1, h2, h3, h4{
        font-family: 'Raleway', sans-serif;
    }

    p{
        font-family: 'Open Sans', sans-serif;
    }
`;

export default GlobalStyle;