import React, { useState } from 'react';
import styled from 'styled-components';
import Toggle from './Toggle';
import { AnimateSharedLayout } from 'framer-motion';
import { useScroll } from './useScroll';
import { reveal } from '../Animation';
import ReactPlayer from 'react-player'

import AboutItems from '../components/Data';

import { titleAnim, pageAnimation, slider, sliderContainer, fade } from '../Animation'
import { motion } from 'framer-motion';


const AboutSection = ({ items }) => {
    const [element, controls] = useScroll();
    const [faqToggle, setFaqToggle] = useState(false);

    return (
        <Container style={{ background: "#fff" }} exit='exit' variants={pageAnimation} initial='hidden' animate='show'>
            <motion.div variants={sliderContainer}>
                <Frame1 variants={slider}></Frame1>
                <Frame2 variants={slider}></Frame2>
                <Frame3 variants={slider}></Frame3>
                <Frame4 variants={slider}></Frame4>
            </motion.div>
            <Title variants={fade}>About Us</Title>
            <Line variants={fade} />
            <AboutText variants={fade}>
                <VideoContainer className="aboutVideo">
                    <iframe src="https://webapps.9c9media.com/vidi-player/1.9.24/share/iframe.html?currentId=2143869&config=ctvnews/share.json&kruxId=&rsid=bellmedianewsprod,bellmediaglobalprod&siteName=CTVNews&cid=%5B%7B%22contentId%22%3A2143869%2C%22ad%22%3A%7B%22adsite%22%3A%22ctv.ctvnews%22%2C%22adzone%22%3A%22embed%22%7D%7D%5D" frameborder="0" allowfullscreen></iframe>
                </VideoContainer>
                <h1>TURN SELF-CARE INTO A WAY OF LIFE</h1>
                <p> Omniscient Wellness Centre delivers a holistic wellness spa experience
                    and benefits using homeopathic treatments, balanced care and education. Under one roof members will have access
                    to all the resources to enable them to live their best and most healthy life.</p>
                <TextLine />
            </AboutText>
        </Container>
    )
}

const VideoContainer = styled(motion.div)`
    display: flex;
    justify-content: center;
    iframe{
        width: 560px;
        height: 315px;
        border-radius: 8px;
    }
`

const Container = styled(motion.div)`
    min-height: 100vh;
    overflow: hidden;
`

const Title = styled(motion.h1)`
    text-align: center;
`

const Line = styled(motion.div)`
  width: 5rem;
  height: 0.25rem;
  background: #5ba091;
  margin-left: auto;
  margin-right: auto;
`

const TextLine = styled(Line)`
    width: 100%;
    
`

const AboutText = styled(motion.div)`
    padding: 5rem 10rem;
    @media (max-width: 1300px){
            display: block;
            padding: 2rem 2rem;
            text-align: center;
    }
`

//frame animation
const Frame1 = styled(motion.div)`
    position: fixed;
    left: 0;
    top: 10%;
    width: 100%;
    height: 100vh;
    background: #5ba091;
    z-index: 2;
`

const Frame2 = styled(Frame1)`
    background: #fff;
`

const Frame3 = styled(Frame1)`
    background: #5ba091;
`

const Frame4 = styled(Frame1)`
    background: #fff;
`


export default AboutSection;