import React from 'react'
import styled from 'styled-components';
import { motion } from 'framer-motion';

import { fade, reveal } from '../Animation'

const MenuCat = ({ filterItems, items }) => {
  return (
    <ButtonContainer className="btn-container" initial="hidden" animate="show" variants={fade}>
      <StyleButton className="filter-btn" onClick={() => filterItems('all')}>All</StyleButton>
      <StyleButton className="filter-btn" onClick={() => filterItems('breakfast')}>Breakfast</StyleButton>
      <StyleButton className="filter-btn" onClick={() => filterItems('lunch')}>Lunch/Dinner</StyleButton>
      <StyleButton className="filter-btn" onClick={() => filterItems('bowls')}>Bowls</StyleButton>
      <StyleButton className="filter-btn" onClick={() => filterItems('drinks')}>Drinks</StyleButton>
    </ButtonContainer>
  )
}

const ButtonContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  padding-top: 2%;
  @media (max-width: 1300px){
        display: block;
        text-align: center;
    }
`

const StyleButton = styled(motion.button)`
  background: transparent;
  border-color: transparent;
  font-size: 1rem;
  text-transform: capitalize;
  margin: 0 0.5rem;
  letter-spacing: 1px;
  padding: 0.375rem 0.75rem;
  color: black;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  border: #5ba091 1px solid;
  transition: 0.3s ease-in-out;
  &:hover{
      background: #5ba091;
      color: white;
  }

  @media (max-width: 1300px){
        margin-top: 10px;
    }
`

export default MenuCat
