import React from 'react'
import styled from 'styled-components';
import emailjs from 'emailjs-com';
import { Instagram } from '@styled-icons/boxicons-logos/Instagram';

const Form = () => {

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('gmail', 'template_t6f81g8', e.target, 'user_qKYwvsFCQJVj97pxj9FsY')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset();
    }

    return (
        <div className="form-content">
            <StyledForm className="form" onSubmit={sendEmail}>
                <div className="form-inputs">
                    <StyledInput id="name" type="text" placeholder="Name" name="from" />
                    <StyledInput id="email" type="email" placeholder="Email" name="fromEmail" />
                    <StyleArea placeholder="Message" name="message"></StyleArea>
                </div>
                <StyledButton type="submit" className="submit">Send</StyledButton>

                <a target="_blank" href="https://www.instagram.com/spacafeottawa/"><StyledInstagram /></a>
            </StyledForm>
        </div>
    )
}

const StyledForm = styled.form`
`

const StyledInput = styled.input`
    display: block;
    margin: 10px 0;
    width: 30vw;
    height: 4vh;
    border: 1px solid black;
    border-radius: 5px;
    position: relative;
    left: 33%;
    ::placeholder{
        font-size: 15px;
        padding: 0.5rem;
    }
    @media (max-width: 1300px){
        left: -80px;
        width: 80vw;
        bottom: -50px;
    }
`

const StyledInstagram = styled(Instagram)`
position: relative;
    width: 100px;
    color: white;
    background: black;
    border-radius: 30px;
    left: 41%;
    top: 100px;
    @media (max-width: 1300px){
        left: 18%;
        top: 20px;
    }
`

const StyleArea = styled.textarea`
    display: block;
    margin: 10px 0;
    width: 30vw;
    height: 4vh;
    border: 1px solid black;
    border-radius: 5px;
    position: relative;
    left: 33%;
    max-width: 30vw;
    min-width: 30vw;
    max-height: 30vh;
    min-height: 10vh;
    ::placeholder{
        font-size: 15px;
        padding: 0.5rem;
    }
    @media (max-width: 1300px){
        left: -80px;
        bottom: -50px;
        min-width: 80vw;
        max-width: 80vw;
        max-height: 50vh;
        min-height: 10vh;
    }
`

const Message = styled(StyledInput)`
    height: 100px;
    
`

const StyledButton = styled.button`
    position: relative;
    left: 47.5%;
    color: black;
    @media (max-width: 1300px){
        left: -80px;
        width: 80vw;
        bottom: -50px;
    }
`

export default Form
