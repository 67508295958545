import React from 'react'
import styled from 'styled-components';
import Logo from '../img/logo.png';

const Footer = () => {
    return (
        <div>
            <Foot>
                <TopContainer>
                    <Image src={Logo} alt="" />
                    <Hours>
                        <h2>Hours</h2>
                        <strong>Monday-Saturday</strong>
                        <p>8am-5pm</p>
                        <strong>Sunday</strong>
                        <p>9am-5pm</p>
                    </Hours>
                    <MapContainer>
                        <Map src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2800.0358706764437!2d-75.69749028416548!3d45.42877824382689!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cce04fdb1b17dc9%3A0x6d3c0ecfc8453ca!2s18%20Murray%20St%2C%20Ottawa%2C%20ON%20K1N%201G9!5e0!3m2!1sen!2sca!4v1621448836026!5m2!1sen!2sca" width="400" height="300" allowfullscreen="" loading="lazy"></Map>
                    </MapContainer>
                </TopContainer>
                <HR />
                <Bottom>
                    <p>Created By: <span><a target="_blank" href="https://www.linkedin.com/in/markonikic/">Marko Nikic</a></span></p>
                </Bottom>
            </Foot>
        </div>
    )
}

const Foot = styled.footer`
    position: relative;
    max-width: 100%;
    background: #f8f5f5;
    bottom: -20px;
    top: 0;
`

const Image = styled.img`
    position: relative;
    width: 150px;
    left: 15%;
    padding: 3rem 3rem;
    top: 90px;
    @media (max-width: 1300px){
        top: -20px;
        left: 110px;
        width: 100px;
    }
    //ipad pro
    @media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {
    width: 100px;
    left: 25%;
    top: 1vh;
    }
`

const HR = styled.hr`
    width: 80vw;
    height: 3px;
    border: none;
    background: #313639;
`

const TopContainer = styled.div`
    position: relative;
`

const Hours = styled.div`
    position: inherit;
    width: 10vw;
    left: 35%;
    top: -10vh;
    text-align: center;
    @media (max-width: 1300px){
        width: 40vw;
        left: 30%;
        top: -50px;
    }
    //ipad pro
    @media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {
    left: 40%;
    top: -13vh;
    }
`

const MapContainer = styled.div`
    position: relative;
    width: 100%;
`


const Map = styled.iframe`
    position: relative;
    width: 500px;
    height: 300px;
    left: 55%;
    border: none;
    top: -30vh;
    margin-bottom: -280px;
    border-radius: 20px;
    @media (max-width: 1300px){
        width: 230px;
        height: 230px;
        left: 20%;
        top: 10%;
        margin-bottom: 0px;
        margin-top: -10%;
        align-items: center;
    }
    //ipad pro
    @media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {
    width: 400px;
    height: 200px;
    left: 30%;
    }

`

const Bottom = styled.div`
    text-align: center;
    padding-bottom: 1rem;
    font-size: 10px;
    a{
        color: grey;
        text-decoration: none;
        &:hover{
            color: #5ba091;
        }
    }
    p{
        font-size: 13px;
        color: grey;
    }
`

export default Footer
