import React, { useState } from 'react'
import styled from 'styled-components';
//photos
import Main from '../img/fruitoat.jpg';
import Wave from '../components/Wave';

import { pageAnimation, titleAnim, fade, photoAnim } from '../Animation';
import { motion } from 'framer-motion';

import TopHome from '../components/TopHome';
import Carousel from '../components/Carousel';
import Cards from '../components/Cards';
import ScrollTop from '../components/ScrollTop';
import FaqSection from '../components/FaqSection';
import AboutSection from '../components/AboutSection';


const About = () => {
    return (
        <div className="container">
            <AboutSection />
            <FaqSection />
        </div>
    )
}

export default About
